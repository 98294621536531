import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 图标
import '@mdi/font/css/materialdesignicons.css'

import { Toast, Tabbar, TabbarItem, List, PullRefresh, ImagePreview, Uploader, Field, Step, Steps, Dialog, Tab, Tabs, Popup } from 'vant'
import 'vant/lib/index.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// 全局注册
Toast.setDefaultOptions('loading', { forbidClick: true })
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.ImagePreview = ImagePreview
// vant
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Uploader)
Vue.use(Field)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Popup)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
