<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style >
#app{
    background-color: RGB(240,242,245);
}
.van-steps {
  background-color: #fafafa;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 90%;
}
.van-steps__items {
  background-color: #fafafa;
}
.van-step {
  background-color: #fff;
  border-radius: 10px;
}
.van-step__circle-container {
  top: 47px !important;
  color: #5f9cf5 !important;
}
.van-step__line {
  top: 56px !important;
  color: #5f9cf5 !important;
}
</style>
